/**
 * Shared public environment config
 */
export const ENV_CONFIG = {
  firebase: {
    "projectId": "bb-web-c1b44",
    "appId": "1:684923042315:web:5c604e3728d0f23602a50e",
    "storageBucket": "bb-web-c1b44.appspot.com",
    "apiKey": "AIzaSyBTOYS_I_Km-kMTnERpVi7twIXMljVqTfI",
    "authDomain": "bb-web-c1b44.firebaseapp.com",
    "messagingSenderId": "684923042315",
    "measurementId": "G-NRSY9MCQB9"
  }
};
